import React from "react"
import { graphql } from "gatsby"
import Head from "../components/head"
import Layout from "../components/layout"
import Intro from "../components/intro"
import Blog1 from "../components/blog1"
import Blog2 from "../components/blog2"

export const query = graphql`
  query {
    allFile(filter: {extension: {eq: "md"}, name: {eq: "leistungen"}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              title
              intro {
                text
                title
              }
              intro2 {
                text
                title
              }
            }
          }
        }
      }
    }
  }
`

export default function Services(props) {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <Layout>
      <Head title={data.title} description={data.intro.text} robots="index, follow" />
      <Intro subtitle={data.title} title={data.intro.title} titleHeading="h1" text={data.intro.text} />
      <Blog1 />
      <div id="igel" className="bg-green-lighter overflow-hidden">
        <Intro subtitle="IGeL-Leistungen" title={data.intro2.title} titleHeading="h3" text={data.intro2.text} />
        <Blog2 />
      </div>
    </Layout>
  )
}
