import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Image from "./image"

export default function Blog1() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {frontmatter: {layout: {eq: "services"}}}
    sort: {fields: frontmatter___order, order: ASC}) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              image
              order
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="mt-8 mb-16 sm:mt-12 sm:mb-24 lg:mt-16 lg:mb-32">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="grid sm:grid-cols-2 gap-x-8 gap-y-12 lg:grid-cols-3">
            {data.allMarkdownRemark.edges.map(item => (
              <AniLink
                paintDrip
                to={item.node.fields.slug}
                duration={1}
                hex="F5DCAD"
                key={item.node.frontmatter.title}
              >
                <Image
                  filename={item.node.frontmatter.image.split('/assets/').pop()}
                  alt={item.node.frontmatter.image.split('/assets/').pop().replace(new RegExp('.png|.jpg|.jpeg|.gif'), '')}
                  className="aspect-w-3 aspect-h-2 overflow-hidden mb-6"
                />
                <h3 className="text-2xl font-serif text-petrol sm:text-3xl">
                  {item.node.frontmatter.title}
                </h3>
              </AniLink>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
